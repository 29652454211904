import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
// import axios from 'axios'
import ReqCur from '../components/reqCur'

import PartnersLogos from '../components/partnerLogos.js'

import UXlogos from "../components/uxLogos.js"

import UXProjectsExamples from '../components/UXProjectsExamples.js'
import InternationalStudents from '../components/internationalStudents.js'
import Instalments from '../components/instalments.js'
import Countdown from '../components/Countdown.js'

// import {StripeProvider} from 'react-stripe-elements';
// import {Stripe} from 'stripe.js'
// import Checkout from '../components/checkout';

import Contacts from '../components/contactform';
import Covid from '../components/covid';
import Scholarships from '../components/scholarships.js'

import UXBanner from '../pics/ux_design_bootcamp_2023.png'

import Marina from '../pics/team/marina_tudela.jpg'
import Margarita from '../pics/team/margarita_barcelona_code_school.jpg'

import Mateo from '../pics/students/mateo.png'
import Chris from '../pics/students/chris.png'
import Brett from '../pics/students/brett.png'


import coursicab from '../pics/partners/coursicab.png'


let UXBootcamp = () => {
	let [weeks, setWeeks] = useState({
		week1: 'none',
		week2: 'none',
		week3: 'none',
		week4: 'none',
		week5: 'none',
		week6: 'none',
		week7: 'none',
		week8: 'none',
		week9: 'none',
	})


	let showFull = (event) => {
		weeks[event.target.title] === 'block' ? setWeeks({ ...weeks, [event.target.title]: 'none' }) : setWeeks({ ...weeks, [event.target.title]: 'block' })
	}

	// let [courses, setCourses] = useState([])

	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/UXbootcamp')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])
	let courses = [
		{
			durationdays: 61,
			durationweeks: 9,
			fulldesc1: "9-week course, in-person, in English, full-time.",
			fulldesc2: "Registration prepayment 580€.",
			fullprice: 5800,
			image: "https://barcelonacodeschool.com/static/ux_design_bootcamp_2021-949ad0842b70a31bb60a30a53771e49d.png",
			priceoptions: ['5800'],
			shortdesc: "Become a UX Designer in 9 weeks",
			signupprice: 580,
			startdates: ['September 16 - November 15, 2024', 'January 6 - March 7, 2025'],
			starttime: "9:00",
			tag: "UXbootcamp",
			title: "UX/UI Design Bootcamp"
		}
	]

	let renderCourses = () => {
		return courses[0].startdates.map((date, i) => (
			<div key={i} className="whiteCard bottomSpace flex300" >
				<span>
					<h2><span className='highlighter'>{date}</span></h2>
					<h3>{courses[0].title}</h3>
					{i === 0 &&
						<h3>Starts in <Countdown endDate={`${date.split(' - ')[0]} ${date.slice(-4)} 09:00`} />
						</h3>
					}
					<p>{courses[0].shortdesc}</p>
				</span>
				<span>
					<p>{courses[0].fulldesc1}</p>
					<p>{courses[0].fulldesc2}</p>
					{date.includes('January 8') ? <p className='highlighter'>Registration closed</p>
						:
						<Link to="/register" state={{ course: courses[0].title, date: date, cost: courses[0].signupprice, images: [courses[0].image] }}>
							<button style={{ 'marginBottom': '1em' }}>Sign up</button>
						</Link>}
					{/*<p className='highlighter'>{date.includes('14')&&<strong>Group is full</strong>}</p>*/}

					{/*<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>*/}
				</span>
			</div>
		))
	}


	return <div className="flexContent">
		<Helmet
			title="UX/UI Design Bootcamp in Barcelona Code School"
			meta={[
				{ name: 'description', content: 'Intensive 9-week course to become a UX Designer. UX design course which lands you a job. Full-time UX/UI Design Bootcamp in Barcelona Code School. ' },
				{ name: 'keywords', content: 'ux design bootcamp, ux bootcamp, design bootcamp in barcelona, ux ui design bootcamp in barcelona, ui bootcamp, ux course in barcelona, ux workshop in barcelona, learn ux design, learn Sketch, learn Adobe XD, learn InVision, learn InVision Studio, learn Flinto' },
				{ property: "og:title", content: "UX/UI Design Bootcamp in Barcelona Code School" },
				{ property: "og:description", content: "UX/UI Design Bootcamp in Barcelona Code School. Intensive 9-week course to become a UX Designer. UX design course which lands you a job." },
				{ property: "og:image", content: "https://barcelonacodeschool.com/files/pics/ux_design_bootcamp_2021.png" },
				{ property: "og:url", content: "https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/" },
				{ name: "twitter:card", content: "summary_large_image" },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
			]}
			script={[
				{ "src": "https://js.stripe.com/v3/" }
			]}
		/>



		<img
			className='banners flex1200 offsetImage'
			src={UXBanner}
			alt='UX/UI Design Bootcamp in Barcelona Code School' />


		<h1 className='transparentContainer flex225'><span className='highlighter'>UX/UI Design Bootcamp</span> in Barcelona Code School</h1>

		<h2 className='transparentContainer flex450'>Become a designer and find a job, start freelancing, or become the UX Design lead in your company!</h2>


		<div className='transparentContainer flex225'>
			<div className='whiteCard'>
				<UXlogos />
			</div>
		</div>

		<div className='transparentContainer flex300'>
			<article className='whiteCard'>
				<ul className='neonList'>
					<li>Intensive <span className='highlighter'>in-person hands-on</span> training</li>
					<li>Zero to hero in <span className='highlighter'>9 weeks</span></li>
					<li><span className='highlighter'>Monday to Friday</span>, 9:00 - 18:00</li>
					<li>The course is taught in <span className='highlighter'>English</span></li>
					<li>A <strong className='highlighter'>graduate certificate</strong></li>
					<li>Maximum <strong className='highlighter'>6 students</strong> per group</li>
					<li>Job support</li>
				</ul>
			</article>
		</div>

		<div className="transparentContainer">
			<h4>Share on social media</h4>
			<div className="socialsharebuttons">
				<div className="socialshare" style={{ 'marginLeft': 0 }}>
					<a
						className="twitter-share-button "
						href="https://twitter.com/intent/tweet?text='UX/UI Design Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
						data-size="large"
					>
						<button>tweet</button>
					</a>
				</div>

				<div
					className="fb-share-button socialshare"
					data-href="https://developers.facebook.com/docs/plugins/"
					data-layout="button"
					data-size="small"
				>
					<a
						target="_blank"
						href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/"
						className="fb-xfbml-parse-ignore"
					>
						<button>facebook</button>
					</a>
				</div>
			</div>
		</div>


		<article className='transparentContainer flex450'>
			<h2>Get the syllabus</h2>
			<div className='whiteCard'>
				<p>Subscribe to our mailing list and get the syllabus now.</p>
				<ReqCur course="UX/UI Design Bootcamp" link="https://barcelonacodeschool.com/files/UX_UI_Design_Bootcamp_Syllabus_Full_Time.pdf" courselink="https://barcelonacodeschool.com/ux-design-bootcamp-in-barcelona-code-school/" />
			</div>
		</article>

		<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
				<h2>5800€</h2>
				<p>10% paid upon registration, the remaining is split in two payments due before course begins.</p>
				<Instalments />
			</div>
		</article>

		<article className="transparentContainer flex1200">
			<h2 id="cohorts">Access<span id="dates" style={{ position: 'relative', top: '-50', visibility: 'hidden' }}>.</span></h2>
			<div className='flexContent'>
				{/*<Covid course={'jsinperson'}/>*/}
				{courses.length > 0 && renderCourses()}
			</div>
		</article>


		<article className="transparentContainer flex450">
			<h2>Overview</h2>
			<div className='whiteCard'>
				<p>In this UX/UI Design course, we are going to go through <span className='highlighter'>all the phases of a UX Project</span> exploring both the theoretical and the practical side of each step.</p>
				<p><strong>This course is perfect for</strong>:</p>

				<ul className='neonList'>
					<li>Those who want to transition into <strong>UX/UI Design career</strong></li>
					<li><strong>Visual Designers</strong> who want to understand and apply principles of Experience Design</li>
					<li><strong>Project Managers, Developers, Marketers, Psychologists, Communicators, Advertisers</strong> and other professionals who want to learn the processes used in the field of UX, UI and human-centred design</li>
					<li><strong>Entrepreneurs</strong> who want to <strong>validate their ideas</strong> and learn how to <strong>create captivating products to attract and retain users</strong></li>
					<li>Anyone who wants to upgrade themselves professionally and have a more market-relevant profile</li>
				</ul>
			</div>
		</article>


		<article className="transparentContainer flex900">
			<h2>Topics by week</h2>
			<div>
				<div className='weeks whiteCard'>
					<h3>Week 1: Introduction to UX Design and Design Sprint 3.0 <span onClick={(e) => showFull(e)} title='week1' className={weeks.week1 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week1ful' style={{ display: weeks.week1 }}>
						<ul className='neonList'>
							<li>Introduction to the history and definitions of UX and Design Thinking </li>
							<li>Design Sprint project process</li>
							<li>Contrast the feasibility of the project and defining the MVP</li>
						</ul>

						<p><strong>Primary activity</strong>: First week - First project! We will introduce you to the core principles through a practical project using one of the most reliable  methodologies - Google Design Sprints.</p>
						<p><strong>Project</strong>: Design Sprint Project - problem identification, solution development, prototyping and market feasibility.</p>
						<p><strong>Tools</strong>: Design Sprint, Figma</p>
					</div>
				</div>

				<div className='weeks whiteCard'>
					<h3>Week 2: Team Project Briefing, Initial Problem Statement and beginning of Discovery  <span onClick={(e) => showFull(e)} title='week2' className={weeks.week2 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week2ful' style={{ display: weeks.week2 }}>

						<ul className='neonList'>
							<li>Group Project Briefing</li>
							<li>Definition of User Research: Quantitative vs. Qualitative</li>
							<li>Real World investigation through Surveys </li>
							<li>On The Fly Interviews</li>
							<li>Core Empathy UX Methods</li>
						</ul>

						<p><strong>Primary activity</strong>: You are going to discover your group project through the briefing process and start analyzing it. During this week you will analyze the current market relationship in real time with the problem space and look for opportunities.</p>
						<p><strong>Projects</strong>: This week you will start working on The Style Guide Group Challenge, which will be tested in public, alongside The Team Project.</p>
						<p><strong>Tools</strong>: Figma, Google Surveys, Trello</p>
					</div>
				</div>

				<div className='weeks whiteCard'>
					<h3>Week 3: Project Definition & Information Architecture <span onClick={(e) => showFull(e)} title='week3' className={weeks.week3 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week3ful' style={{ display: weeks.week3 }}>
						<ul className='neonList'>
							<li>Research analysis: User Persona, Heuristic Analysis and Benchmarking</li>
							<li>Choosing the right Target</li>
							<li>Information Architecture</li>
							<li>Task Flows & Site Maps </li>
							<li>Agile and Lean UX Masterclass</li>
						</ul>

						<p><strong>Primary activity</strong>: This week you will convert your earlier research findings into actionable blueprints through the definition phase which will allow you to begin the process of wireframing. You will also receive masterclasses on both Agile and Lean methodologies.</p>
						<p><strong>Projects</strong>: You'll test you Style Guides in public and keep working on the group project applying the methodologies learned.</p>
						<p><strong>Tools</strong>: Figma</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 4: Ideation Phase: Low Fidelity Sketching & Medium Fidelity Testing <span onClick={(e) => showFull(e)} title='week4' className={weeks.week4 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week4ful' style={{ display: weeks.week4 }}>
						<ul className='neonList'>
							<li>Components Drawing</li>
							<li>Low-Fidelity Wire framing</li>
							<li>Medium Fidelity Usability Testing</li>
						</ul>

						<p><strong>Primary activity</strong>: This week the team will work intensely to produce low fidelity screens by hand which will be brought to medium fidelity testing through Miro. You may choose to rumble or go all, diverging into independent strands with specific roles of Information Architect, UI Designer, and Project Manager.</p>
						<p><strong>Projects</strong>: Keep working on the team project applying the methodologies learned.</p>
						<p><strong>Tools</strong>: Miro and Figma</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 5: Prototyping and Usability Testing Sessions <span onClick={(e) => showFull(e)} title='week5' className={weeks.week5 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week5ful' style={{ display: weeks.week5 }}>
						<ul className='neonList'>
							<li>Component Creation inside Figma</li>
							<li>From wireframes to a functional prototype </li>
							<li>Real World Feedback Sessions in the public with real users</li>
							<li>Retrospectives and Project Summaries</li>
						</ul>

						<p><strong>Primary activity</strong>: This week you will complete your projects through objective testing sessions challenging yourselves to actively go into the real world and getting the critical feedback which can inform future iterations.</p>
						<p><strong>Projects</strong>: Team Project Grande Finale.</p>
						<p><strong>Main tools</strong>: Figma</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 6: Personal Project: Discovery <span onClick={(e) => showFull(e)} title='week6' className={weeks.week6 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week6ful' style={{ display: weeks.week6 }}>
						<ul className='neonList'>
							<li>Masterclass in Project Validation </li>
							<li>Concept Pitch </li>
							<li>Decide with course tutors on specific UX Research Methods</li>
							<li>Empathy Phase</li>
							<li>Workflow Creation </li>
							<li>Plan Interviews</li>
						</ul>

						<p><strong>Primary activity</strong>: You will immediately apply your skills to your own passion project and show confidence in creating a backlog of chosen research methodologies to bring into your own work.</p>
						<p><strong>Projects</strong>: Personal Passion Project.</p>
						<p><strong>Tools</strong>: Figma, Google Surveys, Trello</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 7: Personal Project: Choose a Target <span onClick={(e) => showFull(e)} title='week7' className={weeks.week7 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week7ful' style={{ display: weeks.week7 }}>
						<ul className='neonList'>
							<li>Independent Desk Research</li>
							<li>UX Discovery Summary </li>
							<li>Information Architecture</li>
						</ul>

						<p><strong>Primary activity</strong>: This week, you will enter into the defintion phase of your personal project and outline the Information Architecture of your MVP.</p>
						<p><strong>Projects</strong>: Personal Project.</p>
						<p><strong>Tools</strong>: Figjam, Figma</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 8: Personal project testing and finalizing <span onClick={(e) => showFull(e)} title='week8' className={weeks.week8 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week8ful' style={{ display: weeks.week8 }}>
						<ul className='neonList'>
							<li>From Wireframes to a functional prototype </li>
							<li>Usability Feedback Sessions</li>
							<li>Design Loop Retrospection</li>
						</ul>

						<p><strong>Primary activity</strong>: This week you will complete your personal projects through objective testing sessions challenging yourselves, getting resourceful and conducting usability sessions .</p>
					</div></div>

				<div className='weeks whiteCard'>
					<h3>Week 9: Portfolio website, presentations, job prep <span onClick={(e) => showFull(e)} title='week9' className={weeks.week9 == 'block' ? 'downArrowAbsUpsideDown' : 'downArrowAbs'}>&#8595;</span></h3>
					<div className='weekContent' title='week9ful' style={{ display: weeks.week9 }}>
						<ul className='neonList'>
							<li>Create your Portfolio Website </li>
							<li>Careers Masterclass Conference on What to expect as a Junior UX Designer </li>
							<li>Lecture on Jobs Available within UX Industry</li>
							<li>Final Presentation of either Team Project or Personal Project in front of Senior Designers.</li>
						</ul>
						<p><strong>Primary activity</strong>: You will take both portfolio case studies and convert them into your website which will give you the prerequisite portfolio to immediately start applying for work as a junior UX Designer.</p>
						<p><strong>Projects</strong>: Website Portfolio.</p>
						<p><strong>Tools</strong>: Figma, Framer</p>
					</div>

				</div>
			</div>
		</article>


		<article className="transparentContainer flex600">
			<h2>What our students say</h2>
			<div className='flexContent'>
				<div className='whiteCard flex225'>
					<p><strong>"Great intensive course!</strong> Good balance between theory lessons and projects. The small group is perfect to get personal assistance and resolving any doubt. The teachers are very good at adjusting their lessons to students' backgrounds and skills. Also, there are enough projects and content to put a portfolio together at the end of the Bootcamp."</p>
					<p className="author">— Mateo Pardo</p>
				</div>
				<div className='whiteCard flex225'>
					<p>"Studying process was very intense and full of knowledge. I think it <strong>exceeded all my expectations</strong>. Our professional teachers touched every possible topic during 9 weeks of a boot camp. They introduced us to the world of UX/UI, different platforms, tools and motivation videos. Our days were not like routine learning in a boring school, on the contrary, it was fun, easy and educational."</p>
					<p className="author">— Polina</p>
				</div>
				<div className='whiteCard flex225'>
					<p>"The course was a lot of fun to do! Absolutely worth it if you are looking to experience and learn UI/UX design. Instead of spending too much time on theory we jumped right on getting practical. Marina Tudela is friendly and explains everything very clear. She teaches you how to really <strong>get inside the head</strong> of the user/customer. Muchissimas gracias Marina!"</p>
					<p className="author">— R da Silva</p>
				</div>
				<div className='whiteCard flex225'>
					<p>"Very good UX course that helped me be sure that I want to activate in this field. I liked the overall environment and things we learnt."</p>
					<p className="author">— Vasilica Perianu</p>
				</div>
			</div>
		</article>


		<article className='transparentContainer flex300'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>

				<ul className='neonList'>
					<li>You will be able to <span className='highlighter'>design interactive products</span> that are easy to use, user-centered and that people love.</li>
					<li>You will learn to design great experiences for real products that can improve everyone’s life.</li>
					<li>If you are an entrepreneur, you will gain the necessary skills to <span className='highlighter'>bring your product to the next level</span> by building the best experience for your customers.</li>
					<li>If you are a developer, product manager, QA or any other field that requires direct contact with users you will learn the necessary skills to improve your seniority level and make your position more valuable.</li>
					<li>By the end of the course, you will have <span className='highlighter'>3 completed projects</span> ready to be included in your portfolio.</li>
					<li>Option to <span className='highlighter'>land a UX job</span> within one of our hiring partners (internship or junior depending on the previous level of expertise in the technological field).</li>

				</ul>
			</div>
		</article>


		<UXProjectsExamples />

		<article className='transparentContainer flex675 '>
			<h2>Resident instructors</h2>
			<div className='flexContent'>
				<p className='whiteCard flex225'><strong>Chris Kelly</strong>, our current Head UX Instructor has been working as a UX and UI designer for years as a freelancer delivering projects across different industries as well as teaching UX Design at BCS. His ability to understand client's needs and come up with the best outcomes is leading students through their projects and making sure that everybody learns UX in the most practical hands-on approach aligned with user centric workflow. Through a variety of influences, Chris combines his understanding of visual composition and narrative to inform his work believing that the individual and the subconscious psychological drivers that influence us, should be at the heart of every ethical design decision make. </p>

				<p className='whiteCard flex225'><strong>Soline Kiet</strong>, the teaching  assistant, is in charge of helping students with the practical work on their projects, getting better with the UI and mastering Figma. Soline is our own graduate who achieved the great results during her time at the bootcamp and now is helping other students get the best out of the course.</p>

				{/*<p className='whiteCard flex225'><strong>Max Karacun</strong> an UX/UI designer who's work experience includes being a Product Designers in companies like Glovo, Revolut, Founders Factory. He's bringing his expertise along with the invaluable insights into how big and small companies work with UX which is really helpful for the students to understand what to expect and how to prepare best for their new career. On top of that through the years Max has been working as a mentor both in-company and independently teaching User Experience and User Interface design.</p>*/}
			</div>
			<h2>Invited experts</h2>
			<div className='flexContent'>
				<p className='whiteCard flex225'><strong>Marina Tudela</strong>, the Director of UX Studies, has been working as a digital designer for several years, the last ones focusing just on the UX and UI of the products. As a designer, she tries to focus on the balance between usability and visual harmony. She has worked at digital studios like Antiloop and London-based startups like Joivy. Nowadays Marina works as a Product Owner at Haufe and at Barcelona Code School teaching UI/UX Design.</p>
				<p className='whiteCard flex225'><strong>Jon Yablonski</strong> is an award-winning digital designer and a senior product designer currently woring at Mixpanel, previousely worked on the next generation of in-vehicle digital experiences at General Motors. His passion lies at the intersection of interaction design and development. Jon writes about his design experiences for publications including A List Apart, Smashing Magazine, and CSS Tricks. Jon is author of best-selling book "Laws of UX" published by O'Reilly.</p>
				<p className='whiteCard flex225'><strong>Adrià Compte</strong>, Senior Product Designer and Front-End Developer (UX Engineer). Adrià's extensive work experience with companies like Nestle, Softonic, HP and his true passion for teaching make him a walking unicorn for the students. Not only he gives an expert workshop during the bootcamp but also shares his experience which can not be learned from a textbook.</p>
			</div>
		</article>



		{/*	<h2>Main topics</h2>
	<ul className='neonList'>
	<li>Introduction to UX & Human-centred Design</li>
	<li>User Research</li>
	<li>Personas</li>
	<li>User Stories</li>
	<li>UX / UI Design</li>
	<li>Concept Map</li>
	<li>Flow Diagrams</li>
	<li>Wireframes</li>
	<li>Visual fundamentals</li>
	<li>Prototyping</li>
	<li>Testing & Validation</li>
	<li>Final Project</li>
</ul>*/}



		<article className='transparentContainer flex450'>
			<h2>Why Barcelona Code School?</h2>
			<div className='whiteCard'>
				<ul className='neonList'>
					<li><strong>Cutting edge curriculum</strong> tailored to job market and companies’ needs</li>
					<li>Best <strong>students/mentors ratio</strong></li>
					<li>Small groups <span className='highlighter'>up to 6 students</span></li>
					<li><strong>Instructors</strong>, who know how to teach</li>
					<li>Job offers for the students constantly coming from tech companies</li>
					<li>Fast track from beginner to job-ready level</li>
					<li>Life-time <strong>career support</strong></li>
					<li>Real <strong>hiring partners</strong></li>
					<li><strong>Premium location</strong> – new cozy office in the downtown of Barcelona away from the touristic crowds</li>
				</ul>
			</div>
		</article>



		<article className='flex450'>
			<div className="video-container offsetImage" style={{ marginBottom: '1em' }}>
				<iframe width="912" height="538" src="https://www.youtube.com/embed/ql8aKWYfDOQ" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen modestbranding='1'></iframe>
			</div>
		</article>


		<article className='transparentContainer flex300'>
			<h2>Why UX</h2>
			<div className='whiteCard'>
				<p>In today’s digital world people’s experiences have become a crucial aspect for every product, company or
					organization. The transversality of the UX field affects all areas of business as well as the technology and its
					development.</p>
				<p>This bootcamp will help you know your users better, design products that are truly functional and that
					people love, learn the proper design process and provide designs for real life problems.</p>
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2>But I don’t know anything about UX...</h2>
			<div className='whiteCard'>
				<p>Our course is prepared for people with no previous experience in UX Design. Previous experience with related fields such as project or product management, visual or graphic design, development and QA will help you to better understand the industry, but it’s also ok if you want to start from scratch.</p>
				<p>Our instructors will not just teach you all the methodologies but also mentor you through the process of becoming a UX designer. With small groups and best students/teachers ratio, you will have a personalized approach for helping you to succeed. All you need to have is a laptop and a strong desire to become a UX designer.</p>
				<p>The rest is our job.</p>
			</div>
		</article>

		<article className='transparentContainer flex300'>
			<h2>What if I already know something about design?</h2>
			<div className='whiteCard'>
				<p>That’s great! Traditional Design skills (Graphic, Editorial, Digital…) are extremely useful for UX Designers.
					That will help you establish yourself in a higher seniority level once you finish the Bootcamp. We have mindbending
					challenges and more complex concepts to consolidate, strengthen and improve further your skills!</p>
			</div>
		</article>

		<Scholarships />

		<article className='transparentContainer flex300'>
			<h2>Financing</h2>
			<div className='whiteCard'>
				<p>We can assist in getting a student loan which usually have lower rates than consumer loans.</p>
				<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
				<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
				<Instalments />
			</div>
		</article>



		<InternationalStudents />




		<div className='transparentContainer flex450'>
			<h2>FAQ</h2>
			<article className='whiteCard'>
				<div>
					<details> <summary><strong>What computer would I need?</strong></summary><p>You will need to bring your laptop to use during the classes. It can be Mac or Windows.</p>

					</details>
					<details> <summary><strong>What is the language of this course?</strong></summary><p>English.</p>

					</details>

					<details> <summary><strong>Do I have to speak Spanish?</strong></summary><p>For the course you do not need Spanish. Everybody at our school speak English. To feel comfortable in Barcelona Spanish would help but nowadays in most of the places local people do speak English.</p>

					</details>

					<details> <summary><strong>Who is this course for?</strong></summary><p>Beginners. If you don't know what UX design is – this course is for you. If you know what Lean UX is – even better. If you can explain difference between a wireframe and a prototype you will be doing just fine.</p>

					</details>

					<details> <summary><strong>What if I already know how to do some UX or UI Design?</strong></summary><p>Amazing! For students who advance faster or have some prior experience we have more challenging track with hardcore exercises and job opportunities upon graduation.</p>

					</details>

					<details> <summary><strong>What qualification I will get from this course?</strong></summary><p>If completed successfully you will become a UX Designer. Meaning you will know practically how to design awesome product. You will have the skills to start working as an entry UX/UI Designer position.</p>

					</details>

					<details> <summary><strong>Are there any accommodation options for the foreign students?</strong></summary><p>Make sure to check <a href='https://www.uniplaces.com/?utm_source=vero&utm_medium=email&utm_content=control&utm_campaign=Institutionals_Update%20Tracking%20%26%20Infos_%20Actives%2FNon%20Actives&utm_term=Newsletter&vero_id=office%40barcelonacodeschool.com&vero_conv=Thn_9YrfYIN-ruvFIRw_AXMfUSaz0geBOB0eCva7t8s8rOGu5-ZqtuEkgRV0KkLu2FE-G1Qg-iJ5o_8ynL7xjBY93Og9tI-Api0%3D' target="_blank">Uniplaces (use UP25BCS promo code to get 25% discount)</a>, <a href='https://www.spotahome.com/barcelona' target="_blank">Spotahome</a> and <a href='https://barcelona-home.com/en' target="_blank">Barcelona home</a>.</p>
					</details>

					<details> <summary><strong>What about cost of living in Barcelona, how much will I spend apart from the course tuition?</strong></summary><p>Barcelona, compared with other metropolitan European cities, is quite affordable or even cheap place to live. According to the latest survey, the average cost of living for students in Barcelona is around 1000 Euros per month.</p>

						<p>Expect to spend 300-600€ to rent a room or 600-1000€ for the apartment, around 10€ for a lunch in a restaurant, 10€ for a public transport 10-ride pass.</p>

					</details>

					<details> <summary><strong>How good would a typical graduate be after completing the course?</strong></summary><p>You will learn the skills to begin as a UX/UI Desinger, will know how to design, prototype, test and deliver components or full products. You will have a full toolbox and a solid foundation to improve your expertise.</p>

					</details>

					<details> <summary><strong>Is there a pre-course?</strong></summary><p>Yes. We send you materials to study to come prepared!</p>

					</details>

					<details> <summary><strong>How does your price compare to other schools?</strong></summary><p>It’s safe to say that our price is within lower competitive range. We strive to provide the personalized approach thanks to smaller group size and attract highly qualified instructors motivated to share their expertise with students. You will love it, we guarantee!</p>

					</details>
				</div>
			</article>
		</div>


		<div className='flex450'>
			<Contacts
				title={"Inquiry about the course"}
				subject={"Inquiry about UX/UI Design bootcamp"}
			/>
		</div>





		<article className='transparentContainer flex900'>
			<h2>Some of the companies where our graduates work or worked</h2>
			<div>
				<PartnersLogos />
			</div>
		</article>

	</div>
}


export default UXBootcamp
